
import { computed, defineComponent, ref, watch, PropType } from 'vue'
import _ from 'lodash'
import { Audience, AudienceSettingsSchemaItem } from '../../../../../types/GCB2'
import {
    AUDEINCE_ADDITIONAL_SETTINGS,
    AUDIENCE_BOOLEAN_SELECT_DEFAULT_LIST,
    CHANGE_ACTIVATION_TYPES,
} from '../../../../../vars/GCB2'
import TextField from '../../../../inputs/TextField.vue'
import AudienceNumberInput from './AudienceNumberInput.vue'
import AudienceDateInput from './AudienceDateInput.vue'
import AudienceSelect from './AudienceSelect.vue'
import AudienceSettingsBaseInput from '@/components/inputs/AudienceSettingsBaseInput.vue'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import CustomActivatorTreeSelect from '../../../../inputs/CustomActivatorTreeSelect.vue'
import { declOfNum } from '../../../../../utils'
import AudienceTextInput from './AudienceTextInput.vue'
export default defineComponent({
    components: {
        TextField,
        AudienceNumberInput,
        AudienceSelect,
        AudienceSettingsBaseInput,
        CustomActivatorSelect,
        CustomActivatorTreeSelect,
        AudienceDateInput,
        AudienceTextInput,
    },
    props: {
        value: { type: Object, required: true },
        audience: { type: Object as PropType<Audience>, required: true },
        lists: { type: Object, required: true },
        mode: { type: String, required: true },
    },
    setup(props, { emit }) {
        const filterValue = ref({})
        const getBooleanSelectList = (schemaItem: AudienceSettingsSchemaItem) => {
            return [
                { id: true, name: schemaItem.trueText ?? AUDIENCE_BOOLEAN_SELECT_DEFAULT_LIST.trueText },
                { id: false, name: schemaItem.falseText ?? AUDIENCE_BOOLEAN_SELECT_DEFAULT_LIST.falseText },
            ]
        }
        const audience = props.audience
        const isParameterized = computed(() => audience?.settings.parameterized)
        const getAdditionalSettingSchema = (settingKey: string) => {
            return (
                _.get(audience?.settings.additional_params_schema_override, settingKey) ??
                _.get(AUDEINCE_ADDITIONAL_SETTINGS, `${settingKey}.schema`)
            )
        }
        const schema = computed(() =>
            audience?.settings.additional_params_list
                ? audience?.settings.schema?.concat(
                      Object.keys(AUDEINCE_ADDITIONAL_SETTINGS)
                          .filter(key => audience?.settings.additional_params_list?.includes(key))
                          .filter(key => !_.get(AUDEINCE_ADDITIONAL_SETTINGS, `${key}.hidden`))
                          .map(key => ({
                              ...getAdditionalSettingSchema(key),
                              disabled:
                                  props.mode === CHANGE_ACTIVATION_TYPES.edit
                                      ? !_.get(AUDEINCE_ADDITIONAL_SETTINGS, `${key}.canEdit`)
                                      : false,
                          })) as any
                  )
                : audience?.settings.schema
        )
        const deselectAudience = () => {
            emit('select-audience', null)
        }
        const getComponentType = (schemaItem: AudienceSettingsSchemaItem) => {
            if (schemaItem.type === 'select' && schemaItem.list === 'service_list') {
                return 'treeSelect'
            }
            if (schemaItem.type === 'select' || schemaItem.type === 'boolSelect') {
                return 'select'
            }
            if (schemaItem.type === 'text') {
                return 'text'
            }
            if (schemaItem.type === 'date') {
                return 'date'
            }
            if (schemaItem.type === 'numberInput') {
                return 'numberInput'
            }
            if (schemaItem.type === 'textInput') {
                return 'textInput'
            }
        }
        const getInputWidth = (item: AudienceSettingsSchemaItem) => {
            return _.clamp(item.width ?? 0, 70, Number.MAX_SAFE_INTEGER)
        }
        const getList = (item: AudienceSettingsSchemaItem) => {
            if (item.type === 'boolSelect') {
                return getBooleanSelectList(item)
            }
            if (item.list) {
                return props.lists[item.list as string]
            }
            if (item.customList) {
                return item.customList
            }
        }
        const getInputValue = (item: AudienceSettingsSchemaItem, selected: any) =>
            item.multiple ? `${item.label} (${selected.length})` : selected?.name ?? ''
        const getShowTooltip = (item: AudienceSettingsSchemaItem) => _.get(item, 'showTooltip')

        const cl = () => {
            console.log('click')
        }
        return {
            isParameterized,
            schema,
            filterValue,
            getBooleanSelectList,
            getComponentType,
            getInputWidth,
            getList,
            getInputValue,
            declOfNum,
            deselectAudience,
            getShowTooltip,
            cl,
            CHANGE_ACTIVATION_TYPES,
        }
    },
})
